import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const constantRouterMap = [
    { path: '/',
      meta: { title: '主页', noCache: true },
      component: (resolve) => require(['@/views/home'], resolve),
      hidden: true
    },
    {
      path: '/register',
      component: (resolve) => require(['@/views/register'], resolve),
      hidden: true
    },
    // {
    //   path: '/test',
    //   component: (resolve) => require(['@/views/home1'], resolve),
    //   hidden: true
    // },
  ]
  
  export default new Router({
    // mode: 'hash',
    mode: 'history',
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRouterMap
  })